<template>
  <v-container>
    <tl-comp-ranking v-if="comp" :comp="comp" :table="tableView" class="pb-4" />
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlCompRanking from '@/components/gym/comps/comp/tl-comp-ranking'
import Comp from '@/models/Comp'

export default {
  mixins: [toolbarMixin],
  components: {
    tlCompRanking,
  },
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  data: () => ({
    tableView: true,
  }),
  computed: {
    tlToolbarBtnLeft() {
      return { icon: 'tl-arrow-back', action: this.$router.toBackRoute }
    },
    tlToolbarTitle() {
      return 'Results'
    },
    tlToolbarButtons() {
      return [
        {
          icon: this.tableView ? 'tl-view-list' : 'tl-table',
          text: this.tableView ? 'Show list' : 'Show table',
          action: this.toggleView,
        },
      ]
    },
  },
  methods: {
    toggleView() {
      this.tableView = !this.tableView
    },
  },
}
</script>
