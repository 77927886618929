<template>
  <v-card>
    <v-card-title>Info</v-card-title>
    <v-card-text class="text--primary">
      <v-text-field :value="comp.name" label="Competition name" required @input="setCompProp('name', $event)" />
      <v-textarea
        :value="comp.description"
        label="Description"
        rows="3"
        auto-grow
        @input="setCompProp('description', $event)"
      />
      <div v-if="gym.has_routes">
        <div class="body-1">
          Competition type <v-progress-circular v-if="togglingCompType" color="primary" indeterminate />
        </div>
        <v-radio-group
          v-model="climbsType"
          :disabled="comp.expired || togglingCompType"
          class="mt-0"
          @change="updateClimbsType($event)"
        >
          <v-radio color="primary" value="route" label="Routes" />
          <v-radio color="primary" value="boulder" label="Boulders" />
        </v-radio-group>
      </div>

      <tl-datetime-picker v-model="dateTimeStart" label-date="Start" />
      <tl-datetime-picker v-model="dateTimeEnd" label-date="End" />

      <v-textarea
        :value="comp.rules"
        label="Specific rules (optional)"
        rows="1"
        auto-grow
        @input="setCompProp('rules', $event)"
      />
      <v-textarea
        :value="comp.prizes"
        label="Prizes (optional)"
        rows="1"
        auto-grow
        @input="setCompProp('prizes', $event)"
      />

      <div class="d-flex flex-wrap justify-space-between align-center">
        <div class="d-flex justify-start align-center">
          <v-switch
            v-model="approveParticipation"
            label="Approve participation"
            class="pr-4"
            color="primary"
            :disabled="comp.expired"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">tl-info</v-icon>
            </template>
            <span>Users only appear in the rankings after the gym has given their approval.</span>
          </v-tooltip>
        </div>
        <div v-if="approveParticipation">
          <v-btn text link :to="{ name: 'admin.comp.participants', params: { id: comp.id } }" :disabled="comp.expired">
            Participants
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Comp from '@/models/Comp'
import CompClimb from '@/models/CompClimb'
import tlDatetimePicker from '@/components/shared/tl-datetime-picker'

export default {
  components: {
    tlDatetimePicker,
  },
  data: () => ({
    climbsType: 'boulder',
    togglingCompType: false,
  }),
  props: {
    comp: { type: Comp, default: {} },
  },
  computed: {
    ...mapState(['gym']),
    dateTimeStart: {
      get() {
        let dateTime = this.comp && this.comp.date_loggable_start
        return new Date(dateTime)
      },
      set(newVal) {
        let newDateFormatted = newVal ? new Date(newVal).toISOString() : null
        this.setCompProp('date_loggable_start', newDateFormatted)
      },
    },
    dateTimeEnd: {
      get() {
        let dateTime = this.comp && this.comp.date_loggable_end
        return new Date(dateTime)
      },
      set(newVal) {
        let newDateFormatted = newVal ? new Date(newVal).toISOString() : null
        this.setCompProp('date_loggable_end', newDateFormatted)
      },
    },
    approveParticipation: {
      get() {
        return this.comp.approve_participation
      },
      set(newVal) {
        this.setCompProp('approve_participation', newVal)
      },
    },
  },
  watch: {
    'comp.climbs_type': {
      immediate: true,
      handler(newVal) {
        this.climbsType = newVal
      },
    },
  },
  methods: {
    setCompProp(prop, val) {
      this.comp.$update({ [prop]: val })
    },
    async updateClimbsType(newVal) {
      let confirmed = true
      if (this.comp.comp_climbs.length) {
        confirmed = await this.$store.dispatch('dialog/confirm', {
          title: `Change competition type to '${newVal}'?`,
          text: 'Are you sure? This will remove all climbs currently added to this competition.',
        })
      }
      if (!confirmed) {
        this.climbsType = this.comp.climbs_type
        return
      }

      this.setCompProp('climbs_type', newVal)

      let compClimbIds = this.comp.comp_climbs.map(cc => cc.id)
      if (compClimbIds.length) {
        this.togglingCompType = true
        CompClimb.$apiCall('destroyMany', { data: { ids: compClimbIds } })
          .then(() => compClimbIds.forEach(id => CompClimb.find(id).$eject()))
          .finally(() => (this.togglingCompType = false))
      }
    },
  },
}
</script>
