<template>
  <v-container>
    <v-progress-linear v-if="loadingComp" indeterminate />
    <div v-if="comp" class="tl-comp-edit">
      <v-alert v-if="comp.expired" type="info" dense icon="tl-alarm">This competition has expired</v-alert>

      <tl-comp-edit-info class="mb-6" :comp="comp" />

      <tl-comp-edit-climbs class="mb-6" :comp="comp" />

      <tl-comp-edit-system class="mb-6" :comp="comp" />

      <tl-comp-edit-poules class="mb-6" :comp="comp" />

      <v-row>
        <v-col sm="auto">
          <v-btn
            :block="$vuetify.breakpoint.xs"
            :loading="togglingLive"
            :disabled="togglingLive || togglingLiveInc"
            :dark="comp.live || togglingLive || togglingLiveInc"
            :color="comp.live ? 'default' : 'primary'"
            :outlined="comp.live"
            @click="toggleLive()"
          >
            {{ comp.live ? 'Hide for users' : 'Set live' }}
          </v-btn>
        </v-col>
        <v-col sm="auto" v-if="!comp.live || togglingLive || togglingLiveInc">
          <v-btn
            :block="$vuetify.breakpoint.xs"
            :loading="togglingLiveInc"
            :disabled="togglingLive || togglingLiveInc"
            :dark="togglingLive || togglingLiveInc"
            color="primary"
            @click="toggleLiveWithClimbs()"
          >
            Set live with climbs
          </v-btn>
        </v-col>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-col sm="auto">
          <v-btn
            :block="$vuetify.breakpoint.xs"
            :loading="deletingComp"
            :disabled="deletingComp"
            dark
            outlined
            @click="deleteComp()"
          >
            <v-icon left>tl-delete</v-icon>
            Delete competition
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlAutosaveMixin from '@/services/autosave/tl-auto-save.mixin'
import tlSaveBtnMixin from '@/components/shared/save-button.mixin'
import tlCompEditInfo from './tl-comp-edit-info'
import tlCompEditClimbs from './tl-comp-edit-climbs'
import tlCompEditSystem from './tl-comp-edit-system'
import tlCompEditPoules from './tl-comp-edit-poules'
import Climb from '@/models/Climb'
import Comp from '@/models/Comp'

export default {
  mixins: [toolbarMixin, tlAutosaveMixin, tlSaveBtnMixin],
  components: {
    tlCompEditInfo,
    tlCompEditClimbs,
    tlCompEditSystem,
    tlCompEditPoules,
  },
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  data: () => ({
    togglingLive: false,
    togglingLiveInc: false,
    deletingComp: false,
  }),
  computed: {
    ...mapState(['gym']),
    tlToolbarTitle() {
      let compName = this.comp && this.comp.name
      return compName ? `Edit '${compName}'` : 'Edit competition'
    },
    tlToolbarBtnLeft() {
      return { icon: 'tl-arrow-back', action: this.$router.toBackRoute }
    },
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    tlToolbarBanners() {
      let notLiveBanner = {
        icon: 'tl-visibility-off',
        text: 'This competition is not yet visible to users',
        btns: [
          {
            text: 'Set live',
            action: this.toggleLive,
            loading: this.togglingLive,
            disabled: this.togglingLive,
          },
        ],
      }
      if (this.comp && (!this.comp.live || this.togglingLive)) {
        return [notLiveBanner]
      } else {
        return []
      }
    },
    recordsToSave() {
      if (!this.comp) return []
      return [this.comp].concat(this.comp.poules)
    },
  },
  methods: {
    async deleteComp() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: 'Remove competition?',
        text: 'Are you sure? This cannot be undone. The rankings for this competition will be lost.',
        ok: 'Remove',
      })
      if (!confirmed) return
      this.deletingComp = true
      let compGym = await this.comp.comp_gyms[0].$update({
        deleted: true,
        date_deleted: new Date().toISOString(),
      })
      await compGym.$apiSave()
      this.$store.dispatch('toast/success', 'Competition removed')
      this.deletingComp = false

      this.$router.toBackRoute()
    },
    async toggleLive() {
      this.togglingLive = true
      await this.comp.toggleLive()
      this.togglingLive = false
    },
    async toggleLiveWithClimbs() {
      this.togglingLiveInc = true
      let ids = this.comp.comp_climbs.map(cc => cc.climb_id)
      let promiseToggleComp = this.comp.toggleLive()
      let promiseToggleClimbs = Climb.$apiCall('updateMany', {
        params: { gym_id: this.gym.id },
        data: { ids, climb: { live: true } },
      })
      await Promise.all([promiseToggleComp, promiseToggleClimbs])
      this.togglingLiveInc = false
    },
  },
}
</script>

<style lang="sass">
.tl-comp-edit
  max-width: 800px
  margin: 0 auto
</style>
