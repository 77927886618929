<template>
  <div>
    <v-container v-if="$vuetify.breakpoint.mdAndUp">
      <v-progress-linear v-if="loading" indeterminate />
      <div v-if="comp" class="d-flex flex-justify-space-around">
        <v-card width="350" class="mx-auto">
          <v-card-title>Applicants</v-card-title>
          <v-card-text>Users that subscribed for this competition.</v-card-text>
          <v-divider />
          <v-list>
            <v-subheader v-if="!unapproved.length && !loading">Nothing here</v-subheader>
            <v-fade-transition group leave-absolute>
              <tl-comp-participant v-for="compUser in unapproved" :key="compUser.id" :comp-user="compUser" />
            </v-fade-transition>
          </v-list>
        </v-card>
        <v-card width="350" class="mx-auto">
          <v-card-title>Participants</v-card-title>
          <v-card-text>Approved users for this competition.</v-card-text>
          <v-divider />
          <v-list>
            <v-subheader v-if="!approved.length && !loading">Nothing here</v-subheader>
            <v-fade-transition group leave-absolute>
              <tl-comp-participant
                v-for="compUser in approved"
                :key="compUser.id"
                :comp-user="compUser"
                class="participant"
              />
            </v-fade-transition>
          </v-list>
        </v-card>
      </div>
    </v-container>

    <div v-else>
      <v-progress-linear v-if="loading" indeterminate />
      <v-list v-if="currentTabName == 'Applicants'" tile>
        <v-subheader v-if="!unapproved.length && !loading">Nothing here</v-subheader>
        <v-fade-transition group leave-absolute>
          <tl-comp-participant v-for="compUser in unapproved" :key="compUser.id" :comp-user="compUser" />
        </v-fade-transition>
      </v-list>
      <v-list v-if="currentTabName == 'Participants'" tile>
        <v-subheader v-if="!approved.length && !loading">Nothing here</v-subheader>
        <template v-if="!loading">
          <v-fade-transition group leave-absolute>
            <tl-comp-participant v-for="compUser in approved" :key="compUser.id" :comp-user="compUser" />
          </v-fade-transition>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlCompParticipant from './tl-comp-participant'
import Comp from '@/models/Comp'
import CompUser from '@/models/CompUser'

export default {
  mixins: [toolbarMixin],
  components: {
    tlCompParticipant,
  },
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  data: () => ({
    loadingParticipants: false,
    tlToolbarSearchable: true,
  }),
  computed: {
    ...mapState('toolbar', ['currentTabName', 'search']),
    loading() {
      return this.loadingComp || this.loadingParticipants
    },
    tlToolbarBtnLeft() {
      return { icon: 'tl-arrow-back', action: this.$router.toBackRoute }
    },
    tlToolbarTitle() {
      return 'Manage participants'
    },
    tlToolbarTabs() {
      if (this.$vuetify.breakpoint.mdAndUp) return []
      return [ // eslint-disable-line
        { name: 'Applicants' },
        { name: 'Participants' },
      ]
    },
    subscribers() {
      if (!this.comp) return []
      return this.comp.comp_users // Filter on user to prevent error if users are not fully injected yet, while comp_users are.
        .filter(cu => cu.user && cu.user.fullName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        .slice() // Prevent the sort action form mutating the comp_users
        .sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
    },
    unapproved() {
      return this.subscribers.filter(compUser => !compUser.approved)
    },
    approved() {
      return this.subscribers.filter(compUser => compUser.approved)
    },
  },
  created() {
    this.$store.commit('toolbar/setCurrentTabName', 'Applicants')
    this.fetchParticipants()
  },
  methods: {
    fetchParticipants() {
      this.loadingParticipants = true
      CompUser.$apiAll({
        params: {
          json_params: {
            filters: { group_id: this.$route.params.compId },
            includes: 'user',
          },
        },
      }).finally(() => (this.loadingParticipants = false))
    },
  },
}
</script>
