<template>
  <v-row>
    <v-col>
      <v-dialog ref="dialogDate" v-model="dateDialog" :return-value.sync="date" width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field v-model="date" :label="labelDate" prepend-icon="tl-event" hide-details readonly v-on="on" />
        </template>
        <v-date-picker v-model="date" scrollable show-current>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialogDate.save(date)">OK</v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
    <v-col>
      <v-dialog ref="dialogTime" v-model="timeDialog" :return-value.sync="time" width="290px">
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="time"
            :label="labelTime"
            prepend-icon="tl-access-time"
            hide-details
            readonly
            v-on="on"
          />
        </template>
        <v-time-picker v-model="time" scrollable format="24hr">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="timeDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.dialogTime.save(time)">OK</v-btn>
        </v-time-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    value: { type: Date, default: null },
    labelDate: { type: String, default: 'Date' },
    labelTime: { type: String, default: 'Time' },
  },
  data: () => ({
    dateDialog: false,
    timeDialog: false,
  }),
  computed: {
    date: {
      get() {
        return moment(this.value).format('YYYY-MM-DD')
      },
      set(newDate) {
        this.emitNewDatetime(newDate, this.time)
      },
    },
    time: {
      get() {
        return moment(this.value).format('HH:mm')
      },
      set(newTime) {
        this.emitNewDatetime(this.date, newTime)
      },
    },
  },
  methods: {
    emitNewDatetime(date, time) {
      let dateTime = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm').toDate()
      this.$emit('input', dateTime)
    },
  },
}
</script>
