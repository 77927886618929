<template>
  <v-card :disabled="comp.expired">
    <v-card-title>Ranking system</v-card-title>
    <v-card-text class="text--primary">
      <v-switch v-model="splitGender" label="Split ranking on gender" color="primary" />

      <div class="title">Poules</div>
      <p>Split ranking in named groups. Users can pick their poule upon participation.</p>
      <p v-if="!comp.poules.length" class="pt-4 text-center">No poules added</p>

      <v-row v-if="comp.split_gender && comp.poules.length">
        <v-col class="text-center"><v-icon>tl-male</v-icon></v-col>
        <v-col class="text-center"><v-icon>tl-female</v-icon></v-col>
      </v-row>
      <v-row v-for="poule in comp.poules" :key="poule.id" align="end">
        <v-col class="py-0" :class="comp.split_gender ? 'xs-6' : 'xs-12'">
          <v-text-field :value="poule.name" label="Poule name" required @input="poule.$update({ name: $event })">
            <template v-slot:append>
              <v-btn icon :loading="removingPoule(poule)" @click="removePoule(poule)">
                <v-icon>tl-delete</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="comp.split_gender" class="xs-6 py-0">
          <v-text-field :value="poule.name" disabled label="Poule name" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text :loading="addingPoule" :disabled="addingPoule" @click="addPoule">Add poule</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Comp from '@/models/Comp'
import Poule from '@/models/Poule'

export default {
  props: {
    comp: { type: Comp, default: {} },
  },
  data: () => ({
    addingPoule: false,
    removingPoules: {},
  }),
  computed: {
    splitGender: {
      get() {
        return this.comp.split_gender
      },
      set(newVal) {
        this.comp.$update({ split_gender: newVal })
      },
    },
    removingPoule() {
      return poule => {
        return !!this.removingPoules[`loadingPoule${poule.id}`]
      }
    },
  },
  methods: {
    addPoule() {
      this.addingPoule = true
      Poule.$apiCreate({ group_id: this.comp.id }).finally(() => (this.addingPoule = false))
    },
    async removePoule(poule) {
      if (this.removingPoule(poule)) return
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: 'Remove poule',
        text: 'Are you sure?',
      })
      if (!confirmed) return
      this.$set(this.removingPoules, `loadingPoule${poule.id}`, true)
      let success = () => this.$store.dispatch('toast/success', 'Poule removed')
      let failure = () => this.$store.dispatch('toast/error', "Couldn't remove, please try again.")
      poule
        .$apiDestroy()
        .then(success, failure)
        .finally(() => {
          this.$delete(this.removingPoules, `loadingPoule${poule.id}`)
        })
    },
  },
}
</script>
