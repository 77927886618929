<template>
  <v-list-item>
    <tl-list-item-avatar :avatar="compUser.user.avatar" />

    <v-list-item-content>
      <v-list-item-title v-text="compUser.user.fullName"></v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn icon :loading="approving" :disabled="approving" @click="toggleApprove">
        <v-icon>{{ compUser.approved ? 'tl-remove' : 'tl-add' }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import CompUser from '@/models/CompUser'
import tlListItemAvatar from '@/components/shared/tl-list-item-avatar'

export default {
  components: {
    tlListItemAvatar,
  },
  data: () => ({
    approving: false,
  }),
  props: {
    compUser: { type: CompUser, default: {} },
  },
  methods: {
    toggleApprove() {
      let approved = !this.compUser.approved
      let name = this.compUser.user.fullName
      let toastMessage = approved ? `${name} approved` : `${name} removed`
      this.approving = true
      this.compUser
        .$apiUpdate({ approved })
        .then(() => this.$store.dispatch('toast/success', toastMessage))
        .finally(() => (this.approving = false))
    },
  },
}
</script>
