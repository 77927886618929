<template>
  <tl-climbs :loading="loadingComp" show-checks />
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import climbsRouteMixin from '@/components/gym/climbs/tl-climbs-route.mixin'
import { mapState, mapMutations, mapActions } from 'vuex'
import { pluralize } from '@/services/utils'
import tlClimbs from '@/components/gym/climbs/tl-climbs'
import Comp from '@/models/Comp'
import CompClimb from '@/models/CompClimb'

export default {
  mixins: [climbsRouteMixin, toolbarMixin],
  components: {
    tlClimbs,
  },
  props: {
    comp: { type: Comp, default: () => {} },
    loadingComp: { type: Boolean, default: false },
  },
  data: () => ({
    adding: false,
    removing: false,
  }),
  computed: {
    ...mapState(['climbType']),
    ...mapState('climbs', ['view']),
    ...mapState('selection', ['selection']),
    tlToolbarBtnLeft() {
      let action = this.selection.length ? this.clearSelection : this.$router.toBackRoute
      return { icon: 'tl-arrow-back', action }
    },
    tlToolbarDark() {
      return this.selection.length
    },
    tlToolbarTitle() {
      if (this.selection.length) return `${this.selection.length} selected`
      if (!this.compClimbs.length) return `Select ${pluralize(this.climbType)} to add`
      return `${this.compClimbs.length} ${pluralize(this.climbType, this.compClimbs.length)} added`
    },
    tlToolbarButtons() {
      return this.selection.length
        ? [
            { icon: 'tl-add', text: 'Add', disabled: this.saving, loading: this.adding, action: this.addSelection },
            {
              icon: 'tl-remove',
              text: 'Remove',
              disabled: this.saving,
              loading: this.removing,
              action: this.removeSelection,
            },
          ]
        : [
            {
              text: 'Select all filtered',
              action: this.selectAllFiltered,
            },
            {
              text: 'Select all in view',
              action: this.selectAllInView,
              hidden: this.view == 'list' || this.view == 'table',
            },
            {
              text: `${this.view == 'table' ? 'Map' : 'Table'} view`,
              action: () => this.setView(this.view != 'table' ? 'table' : 'map'),
            },
            {
              icon: 'tl-tune',
              iconBadge: !this.$store.getters['climbs/userFilters/isDefault'] ? 'tl-priority-high' : false,
              text: 'Filters',
              action: () => this.$store.commit('nav-right/toggle'),
            },
          ]
    },
    compClimbs() {
      if (!this.comp) return []
      return this.comp.comp_climbs
    },
    compClimbIds() {
      return this.compClimbs.map(cc => cc.climb_id)
    },
    checksFunc() {
      return climb => (this.compClimbIds.includes(climb.id) ? 1 : 0)
    },
    saving() {
      return this.adding || this.removing
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit('climbs/setChecksFunc', vm.checksFunc)
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('climbs/setChecksFunc')
    next()
  },
  methods: {
    ...mapMutations('climbs', ['setView']),
    ...mapActions('selection', { clearSelection: 'clear' }),
    ...mapActions('selection', ['setSelection']),
    addSelection() {
      let toCreate = this.selection
        .filter(climb => !this.compClimbIds.includes(climb.id))
        .map(climb => ({ climb_id: climb.id, group_id: this.comp.id }))

      if (!toCreate.length) return this.clearSelection()

      this.adding = true
      CompClimb.$apiCall('createMany', {
        data: { climb_groups: toCreate },
        injectResponse: true,
      })
        .then(this.clearSelection)
        .finally(() => (this.adding = false))
    },
    removeSelection() {
      let climbIds = this.selection.map(climb => climb.id)
      let compClimbs = this.compClimbs.filter(cc => climbIds.includes(cc.climb_id))
      let compClimbIds = compClimbs.map(cc => cc.id)

      if (!compClimbIds.length) return this.clearSelection()

      this.removing = true
      CompClimb.$apiCall('destroyMany', { data: { ids: compClimbIds } })
        .then(() => {
          compClimbs.forEach(cc => cc.$eject())
          this.clearSelection()
        })
        .finally(() => (this.removing = false))
    },
  },
}
</script>
