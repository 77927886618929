<template>
  <v-card :disabled="comp.expired">
    <v-card-title>{{ comp.climbs_type | capitalize | pluralize }}</v-card-title>
    <v-card-text>
      <div class="body-1">
        <div class="grey--text subtitle-1">{{ comp.comp_climbs.length }} selected</div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn
        text
        :color="comp.comp_climbs.length ? 'default' : 'primary'"
        link
        :to="{ name: 'admin.comp.climbs', params: { compId: comp.id } }"
      >
        Select {{ comp.climbs_type | pluralize }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Comp from '@/models/Comp'

export default {
  props: {
    comp: { type: Comp, default: {} },
  },
}
</script>
