<template>
  <v-card :disabled="comp.expired">
    <v-card-title>Score system</v-card-title>
    <v-card-text class="text--primary">
      <v-row align="center">
        <v-col sm="auto">
          Ranking based on
        </v-col>
        <v-col>
          <v-select v-model="scoreSystem" :items="scoreSystems" item-text="label" item-value="name" />
        </v-col>
      </v-row>

      <div class="title">Explanation</div>
      <component
        :is="currentSystem.component"
        :params="scoreSystemParams"
        :comp="comp"
        is-editor
        @params-change="scoreSystemParams = $event"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="isDefaultParams" text @click="resetParams">
        <v-icon left>tl-restore</v-icon> Restore defaults
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Comp from '@/models/Comp'
import scoreSystems from '../../comps/score-systems/score-systems'

export default {
  props: {
    comp: { type: Comp, default: {} },
  },
  data: () => ({
    scoreSystems,
  }),
  computed: {
    scoreSystem: {
      get() {
        return this.comp.score_system
      },
      set(newVal) {
        this.setCompProp('score_system', newVal)
        this.resetParams()
      },
    },
    scoreSystemParams: {
      get() {
        return this.comp.score_system_params
      },
      set(newVal) {
        this.setCompProp('score_system_params', newVal)
      },
    },
    currentSystem() {
      return this.scoreSystems.find(system => system.name == this.scoreSystem)
    },
    isDefaultParams() {
      return Object.keys(this.currentSystem.paramDefaults).every(key => {
        return this.scoreSystemParams[key] == this.currentSystem.paramDefaults[key]
      })
    },
  },
  methods: {
    setCompProp(prop, val) {
      this.comp.$update({ [prop]: val })
    },
    resetParams() {
      this.setCompProp('score_system_params', { ...this.currentSystem.paramDefaults })
    },
  },
}
</script>
